@media screen and (max-width:600px) {
    .bg-video-wrap2 video {
        height: auto;
    }

    .vision {
        flex-direction: column;
    }

    .banner-heading {
        font-size: 14px;
    }

    .logo-img{
        width: 225px;
        height: auto;
    }

    .owl-theme .owl-nav {
        display: none;
    }

    .client {
        width: 100%;
    }

    .client {
        justify-content: flex-start;
    }

    .serviceCard {
        padding: 14px;
        width: 100%;
    }

    .cards {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .aboutbanner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        width: auto;
        height: 93px;
        background-size: cover;
    }

    /* .cardicon>img {
        height: 56px;
    }

    .cardicon {
        display: flex;
        height: 100%;
        background-color: #3EC5FA;
        justify-content: center;
        align-items: center;
        border-radius: 30px 30px 0px 0px;

    }  */

    .cardContent {
        display: flex;
        flex-direction: column;
        align-items: fixed;
        background-color: white;
        height: 100%;
        border-radius: 0px 0px 30px 30px;
        padding-top: 20px;
    } 


    .container my-5{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

      .cardContent>p {
        text-align: fixed;
        justify-content: center;
        height: 100%    
    } 


    .cardContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        height: 100%;
        border-radius: 0px 0px 30px 30px;
        padding: 10px;
      }

  /*  .cardContent1>p {
        text-align: fixed;
        padding: 0px;
    } */

    /* .cardContent2>p {
        text-align: fixed;
        padding: 2px;
    } */ 


    

    .cardContent>h3 {
        font-size: 17px;
    }

    .cardContent>button {
        background-color: #3EC5FA;
        color: #E9F8FE;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: none;
    }

    .cards3>img {
        width: 316px;
    }

    .card-3-2>button {
        width: 45%;
    }

    /* .team>img {
        height: 170px;
    } */


    .team>div {
        padding: 71px 98px;
        height: 89px;
        border-radius: 4px;
    }

    .team>div>p {
        font-size: 8px;
        width: 200%;
        margin-bottom: 0px;
    }

    .team>div>h4 {
        font-size: 11px;
    }

    .aboutbtn {
        justify-content: flex-start;
    }

    .ecomcontent {
        padding: 5px 6px;
        font-size: 10px;
    }

    .ecomcard {
        margin-bottom: 14px;
    }

    .datacard {
        padding: 21px;
        padding-left: 25%;
        justify-content: normal;
    }

    .data-3 {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 10px;
    }

    .ideacard {
        width: 85%;
        margin: 8px 19px;
    }

    .ideacard>div>h5 {
        font-size: 16px;
    }



    .con-btn,
    .con-btn-2 {
        font-size: 0.75rem;
    }

    .sr-content {
        margin: 3% 0%;
        margin-bottom: 0%;
        border-radius: 0;
        box-shadow: none;
        border: none;
        padding: 20px 0px;
    }

    .contact-btn {
        padding: 3px 17px;
    }

    .left-radius {
        border-radius: 0px 0px 0px 0px;
        width: 21rem;
    }

    .customervoice {
        background-color: white;
        padding: 20px;
        border-radius: 30px;
        margin: 10px;
        width: 77%;
    }

    .chefrover>p{
        text-align: fixed;
        padding: 10px;
        padding-right: 10px;
    }

    .sr-image {
        position: relative;
    }

    .serviceimg,
    .serviceimg2 {
        position: relative;
    }

    .serviceimg>img,
    .serviceimg2>img {
        border-radius: 0px 0px 0px 0px;
        width: 100%;
        margin-top: 2rem;
    }

    .aboutus>.container>div>div>img {
        margin-bottom: 7%;
    }

    .values>div>p {
        width: 100%;
        font-size: 15px;
    }

    .lean {
        border-right: 1px solid white
    }

    .car-btn2 {
        position: relative;
        margin: 10px;
        left: 0;
    }

    .recentposts {
        margin-left: 0%;
    }

    .car-csl .owl-carousel .owl-nav {
        display: block;
    }

    .cnt-service>div>div:first-child {
        border-right: 1px solid white;
    }

    .cnt-service>div>div:nth-child(2) {
        border-right: 1px solid white;
    }

    .cardicon {
        padding: 5% 0%;
    }

    .cardicon>i {
        font-size: 46px;
    }

    .aboutprofile {
        padding: 0px 0px;
    }

    .dropdown-menu .nav-item .nav-link {
        color: #000000;
        text-decoration: none;
        padding: 10px 20px;
    }

    .navbar-nav .dropdown-menu {
        background-color: #ffffff;
    }

    .dropdown-menu .nav-item .nav-link:hover,
    .dropdown-menu .nav-item .nav-link.active {
        color: #990000;
        font-weight: 600;
        text-decoration: none;
        padding: 10px 20px;
    }

    .dropdown-menu .nav-item .nav-link.active>.navbar-light .navbar-nav .nav-link {
        color: #990000;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: white;
    }

    .hm-pr-w {
        width: 100%;
    }

    .hm-pr-w2 {
        width: 100%;
    }

    .home-video-content2 {
        top: 20%;
    }

    .sr-container>div>h1 {
        font-size: medium;
    }

    .blog-sec {
        margin-top: 1rem;
    }

    .sr-content {
        width: 100%
    }

    .mvpcontentx>p:first-child {
        font-size: 30px;
    }

    .mvpcontentx>p:nth-child(2) {
        font-size: 13px;
    }

    .bannr-img {
        height: 44vh;
    }

    .mvp-img {
        width: 100% !important;
    }

    .sticker {
        display: none;
    }

    .mvpcontent .container {
        width: 93%;
    }

    .mvpblockimg,
    .mvpblockimg1 {
        margin-bottom: 20px;
    }

    .intro {
        text-align: center;
        margin-bottom: 7px !important
    }

    .mvpcontent .p {
        margin-bottom: 10px !important;
    }

    .mvplist>li {
        margin-bottom: 10px !important;
        font-size: 13px;
    }

    .mvpcontentx {
        padding: 0px 2px;
    }

    .mainservicepage .container,
    .mainservicepage .container-fluid {
        width: 93%
    }

    .mobilefont {
        font-size: 13px;
    }

    .reviewcard {
        width: 100%;
    }

    .review p {
        font-size: 13px;
    }

    .servicecontent {
        margin: 0px;
    }

    .serviceht {
        height: auto;
    }

    

    .serviceht2 {
        height: auto;
    }

    .bg-video-wrap2 {
        top: 50px;
    }

    .navbar-collapse {
        height: 85vh;
        overflow: scroll;
    }

    .dropdown-menu {
        border: none;

    }

    .mobnav {
        margin-left: 40px;
    }

    .mobnav a:hover {
        color: #1bb8f7;
    }

    a:-webkit-any-link {
        text-decoration: none !important;
    }

    .th-cnt>h1 {
        font-size: 40px;
        margin-top: 20px;
    }

    .th-cnt {
        padding: 20px;
    }

    .Modal-View {
        width: 90%;
    }

    .th-cnt>p {
        font-size: 12px;
    }

    .tnk-btn {
        width: 70%;
        height: 45px;
    }

    /* Dropdown responsive */
    .mob-dr-1 {
        margin-left: 20px;
    }

    .dropdown-item.active {
        background-color: white;
    }

    .cursor-pointer {
        color: black;
    }
}

@media screen and (min-width:1440px) {
    .container {
        max-width: 1140px
    }
}

@media screen and (min-width:768px) and (max-width: 1024px) {
    .banner-heading {
        font-size: 30px;
    }

    .card-3-2>a {
        align-self: center;
    }

    .customervoice {
        margin: 28px;
        height: 100%;
    }

    /* .team>div>p {
        width: 100%;
    } */

    .ecomcard {
        margin-bottom: 20px;
    }

    .sr-content {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        border-radius: 0px;
        margin-bottom: 6%;
    }

    .sr-image {
        position: relative;
    }

    .left-radius {
        border-radius: 0%;
    }

    .ideacard {
        width: 100%;
    }

    .serviceimg {
        position: relative;
    }

    .serviceimg>img {
        border-radius: 0;
        width: 100%;
    }

    .serviceimg2 {
        position: relative;
    }

    .serviceimg2>img {
        border-radius: 0px;
        width: 100%;
    }

    .car-csl .owl-carousel .owl-stage-outer {
        height: 420px;
    }

    .blog-sec {
        margin-top: 5%;
    }

    .cnt-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .cnt-logo {
        display: flex;
        justify-content: space-evenly;
    }

    .mvpcontentx>p:first-child {
        font-size: 50px;
    }

    .mvpcontentx>p:nth-child(2) {
        font-size: 15px !important;
        padding: 0px 20px;
    }

    .bannr-img {
        height: 44vh;
    }

    .mvp-img {
        width: 100% !important;
    }

    .sticker {
        display: none;
    }

    .mvpcontent .container {
        width: 93%;
    }

    .mvpblockimg,
    .mvpblockimg1 {
        margin-bottom: 20px;
    }

    .intro {
        text-align: center;
        margin-bottom: 7px !important;
        font-size: 3rem;
    }

    .mvpcontent .p {
        margin-bottom: 10px !important;
    }

    .mvplist>li {
        margin-bottom: 10px !important;
        font-size: 26px;
    }

    .mvpcontentx {
        padding: 0px 2px;
    }

    .mvpcontent div>p:nth-child(2),
    .mvpcontent div>p:nth-child(4) {
        font-size: 16px;
    }

    .drop-scroll {
        height: 22vh;
        overflow-y: scroll;
    }

    .cardContent>h5 {
        text-align: center;
    }

    .cardContent>p {
        font-size: 14px;
    }

    .team>div>p {
        width: 90%;
    }

    .navbar-collapse {
        height: 85vh;
        overflow: scroll;
    }

    .dropdown-menu {
        border: none;
    }

    .mobnav {
        margin-left: 40px;
    }

    .mobnav a:hover {
        color: #1bb8f7;
    }

    a:-webkit-any-link {
        text-decoration: none !important;
    }

    .intro {
        font-size: 24px;
    }

    .mvplist>li {
        font-size: 16px;
    }

    /* Dropdown responsive */
    .mob-dr-1 {
        margin-left: 30px;
    }

    .dropdown-item.active {
        background-color: white;
    }

    .cursor-pointer {
        color: black;
    }
}